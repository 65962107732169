import React from 'react'
import Layout from '../components/layout'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Spacer from '../components/uielements/Spacer'
import LogotherapyGrid from '../components/sections/LogotherapyGrid'
import Wrapper from '../components/Wrapper'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'

import MainTitle from '../components/uielements/MainTitle'

const LogotherapyPage = () => {
    const { t } = useTranslation()
    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('logotherapy-dept')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('logotherapy-dept')} />
                <MainTitle>{t('logotherapy')}</MainTitle>
                <Spacer top="4" />
                <LogotherapyGrid />
                <Spacer top="4" />
                <LogotherapyGrid postType="adults" />
                <Spacer top="4" />
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export default LogotherapyPage
